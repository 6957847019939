import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { send, init } from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { GREY_BG } from './colors';
import media from "./media";

import Button from './Button';


const Container = styled.div`
    background-color: ${GREY_BG};
    position: relative;
    display: flex;
    justify-content: center;

    ${media.desktop`
        padding: 60px;
    `}
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 200px;
    max-width: 800px;
    > * {
        margin-top: 10px;
    }
`;

const TextArea = styled.textarea`
    height: 100px;
`;

const ButtonContainer = styled.div`
    width: 200px;
    align-self: center;
`;

const Support = () => {
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: 'Support',
        message: '',
        reply_to: '',
    });
    const [captchaNotFinished, setCaptchaNotFinished] = useState(true);

    useEffect(() => {
        init('user_xnF25TiRgA29KazxQoEqq');
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        send(
            'service_ai4jo3h',
            'template_bt0a5cw',
            toSend,
            'user_xnF25TiRgA29KazxQoEqq'
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Your support message was sent. We review it and answer you as soon as possible. Thanks for using Amidy!');
            setToSend({
                from_name: '',
                to_name: 'Support',
                message: '',
                reply_to: '',
            });
        })
        .catch((err) => {
            console.log('FAILED...', err);
            alert('Something went wrond. Your message wasn\'t sent. Please, try again later');
        });
  };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const onChange = () => {
        setCaptchaNotFinished(false);
    };
    
    return <Container>
        <Form onSubmit={onSubmit}>
            <input
                type='text'
                name='from_name'
                placeholder='Your name'
                value={toSend.from_name}
                onChange={handleChange}
            />
            <input
                type='email'
                name='reply_to'
                placeholder='Your email'
                value={toSend.reply_to}
                onChange={handleChange}
            />
            <TextArea
                type="text"
                name='message'
                placeholder='Describe your problem'
                value={toSend.message}
                onChange={handleChange}
            />
            <ReCAPTCHA
                sitekey="6Lfjhh0cAAAAAGJxJkeP7qXXuDGUdZ_37DGTpQSG"
                theme="dark"
                onChange={onChange}
            />
            <ButtonContainer>
                <Button
                    type="submit"
                    disabled={!toSend.reply_to || !toSend.message || captchaNotFinished}
                    title="Send request" 
                    onClick={onSubmit}
                />
            </ButtonContainer>
        </Form>
    </Container>;
};

export default Support;