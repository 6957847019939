import styled from 'styled-components';

import media from "./media";

import { WHITE, PINK_TEXT, GREY_TRANSPARENT_TEXT } from './colors';

export const H1 = styled.h1`
    color: ${GREY_TRANSPARENT_TEXT};
    font-family: 'RubikLight', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    font-size: 48px;
    font-weight: 200;
    margin: 0;

    ${media.desktop`
        font-size: 72px;
    `}
`;
export const H2 = styled.h2`
    color: ${PINK_TEXT};
    font-family: 'RubikLight', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    font-weight: 100;
    font-size: 36px;
    line-height: 50px;
    margin: 0;
`;
export const H3 = styled.h3`
    color: ${WHITE};
    cursor: pointer;
    font-size: 18px;
    font-family: 'RubikRegular', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    
    &:hover {
        color: ${PINK_TEXT};
    }
`;

export const LogoLabel = styled.h4`
    color: ${GREY_TRANSPARENT_TEXT};
    font-family: 'RubikLight', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    font-size: 72px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 200;
    margin: 0 20px;
    text-decoration: none;
`;

export const ButtonLabel = styled.label`
    color: ${WHITE};
    letter-spacing: normal;
    font-style: normal;
    font-size: 15px;
    line-height: 1.875em;
    font-family: 'KarlaRegular', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
`;

export const Body = styled.span`
    color: ${WHITE};
    letter-spacing: normal;
    font-style: normal;
    font-size: 15px;
    line-height: 1.875em;
    font-family: 'KarlaRegular', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
`;

export const SmallLabel = styled.label`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: baseline;
    color: ${WHITE};
    cursor: pointer;
    font-size: 12px;
    font-family: 'KarlaRegular', proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
`;
