import React from 'react';
import styled from 'styled-components';
import { TRANSPARENT_DARK, PINK, DARK_PINK, WHITE } from './colors';
import media from "./media";
import { Body, H2 } from './typography';


const Container = styled.div`
    background-color: ${PINK};
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;

    ${media.desktop`
        flex-direction: row;
        padding: 60px;
    `}
`;

const About = styled.div`
    background-color: ${TRANSPARENT_DARK};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 70px 40px;
`;

const Title = styled(H2)`
    margin-top: -30px;
    margin-bottom: 40px;

    &:hover {
        color: ${DARK_PINK};
    }
`;

const Link = styled.a`
    text-decoration: underline;
    color: ${PINK};

    &:hover {
        color: ${DARK_PINK};
    }
`;

const BodyLink = styled.a`
    text-decoration: underline;
    color: ${WHITE};
    
    &:hover {
        color: ${DARK_PINK};
    }
`;

const CompanySection = () => {
    return <Container id="contacts">
        <About>
            <Link href="https://amidy.net"><Title>Amidy Production Oy</Title></Link>
            <Body>Helsinki, Finland</Body>
            <Body>info@amidy.love</Body>
            <BodyLink href="https://amidy.net" ><Body>https://amidy.net</Body></BodyLink >
        </About>
    </Container>;
};

export default CompanySection;