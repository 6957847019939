import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const mdText = `
# Amidy Privacy Policy

## 1. Information We Collect

Information you give us when using our services
When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your age.
When you complete your profile, you can share with us additional information, such as details on your interests and other details about you, as well as content such as photos or videos. 
We also process your chats with other users as well as the content you publish.
Information collected when you use our services
Usage Information We collect information about your activity on our services, for instance how you use them, e.g. when and how many times certain pages or features.
Device information We collect information from and about the device(s) you use to access our services, including hhardware and software information, IP address, browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser.
Other information with your consent If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you’re using.

## 2. How We Use Information

The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe and to provide you with advertising that may be of interest to you. 
To administer your account and provide our services to you
To help you connect with other users
To ensure a consistent experience across your devices
To provide new Amidy services to you
To serve you relevant offers and ads
To improve our services and develop new ones
To prevent, detect and fight fraud or other illegal or unauthorized activities
To ensure legal compliance

## 3. How We Share Information

Since our goal is to help you make meaningful connections, the main sharing of users’ information is, of course, with other users. We also share some users’ information with service providers and partners who assist us in operating the services.
With other users
You share information with other users when you voluntarily disclose information on the service.
With our service providers and partners
We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.
When required by law
We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
To enforce legal rights
We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.

## 4. Your Rights

We want you to be in control of your information, so we have provided you with the following tools:
Account settings that help you to access, rectify or delete information that you provided to us and that’s associated with your account directly within the service. 
Device permissions. Mobile platforms have permission systems for specific types of device data and notifications, such as phone book and location services as well as push notifications. You can change your settings on your device to either consent or oppose the collection of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose full functionality.
Deletion. You can delete your account by using the corresponding functionality directly on the service.

## 5. How Long We Retain Information

We keep your personal information only as long as we need it for legitimate business purposes and as permitted by applicable law. To protect the safety and security of our users on and off our services, we implement a safety retention window of three months following account deletion. During this period, account information will be retained although the account will of course not be visible on the services anymore.
In practice, we delete or anonymize your information upon deletion of your account (following the safety retention window) or after two years of continuous inactivity.

## 6. Children's Privacy

Our services are restricted to users who are 18 years of age or older. We do not permit users under the age of 18 on our service and we do not knowingly collect personal information from anyone under the age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism available through the service.

## 7. Privacy Policy Changes
Because we’re always looking for innovative ways to help you build meaningful connections, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.
`;

const Container = styled.div`
    padding: 20px;
    background-color: white;

    h1, h2, p {
        text-align: left;
    }
`;

const PrivacyPolicy = () => <Container><ReactMarkdown>{mdText}</ReactMarkdown></Container>;

export default PrivacyPolicy;