import React from 'react';
import styled from 'styled-components';
import { Parallax } from "react-parallax";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import MobileStoreButton from 'react-mobile-store-button';

import { TRANSPARENT_DARK, PINK } from './colors';
import media from "./media";
import { Body, H2 } from './typography';

import BG from './assets/bg.jpg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px;

    ${media.desktop`
    `}
`;

const DownloadIcon = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${PINK};
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled(H2)`
    margin-top: 60px;
`;

const Label = styled(Body)`
    margin-top: 10px;
`;

const DisabledButton = styled.div`
    margin-top: 40px;
`;

const DownloadSection = () => {
    const iOSUrl = 'https://apps.apple.com/us/app/id1577408311';

    return <div>
        <Parallax bgImage={BG} strength={500}>
            <Container>
                <DownloadIcon>
                    <FontAwesomeIcon color={TRANSPARENT_DARK} icon={faDownload} />
                </DownloadIcon>
                <Title>Get Your AMIDY</Title>
                <Label>Let's discover a new online dating experience with the Amidy app.</Label>
                <DisabledButton>
                    <MobileStoreButton
                        store="ios"
                        width={155}
                        url={iOSUrl}
                        linkProps={{ title: 'Amidy App Store' }}
                    />
                </DisabledButton>
                <div style={{ marginLeft: 10 }}>
                    <a href='https://play.google.com/store/apps/details?id=com.amidyproduction.amidy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img width={192} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                    </a>
                </div>
                <div id="highlights" />
            </Container>
        </Parallax>
        
    </div>;
};

export default DownloadSection;