import React from 'react';
import styled from 'styled-components';
import { TRANSPARENT_DARK } from './colors';
import media from "./media";
import { Body } from './typography';


const Container = styled.div`
    background-color: ${TRANSPARENT_DARK};
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;

    ${media.desktop`
        flex-direction: row;
        padding: 60px;
        padding-right: 20px;
    `}
`;

const Footer = () => {
    return <Container>
        <Body>©2021 by Amidy Production Oy</Body>
    </Container>;
};

export default Footer;