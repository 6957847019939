import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const mdText = `
# Amidy Data deletion

### 1. Open side panel in Amidy app
### 2. Click on your name at the top of side panel
### 3. Scroll down to DELETE ACCOUNT button. Click DELETE ACCOUNT and confirm deletion.

 We will be thankful if you also select the reason why you've decided to delete Amidy there of by email info@amidy.love.
`;

const Container = styled.div`
    padding: 20px;
    background-color: white;
    height: 100%;

    h1, h2, h3, p {
        text-align: left;
    }
`;

const Deletion = () => <Container><ReactMarkdown>{mdText}</ReactMarkdown></Container>;

export default Deletion;