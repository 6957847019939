import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import { Link as HrefLink } from 'react-router-dom';

import media from "./media";
import { WHITE, TRANSPARENT_DARK, PINK_TEXT, GREY_TRANSPARENT_TEXT } from './colors';

import LogoImg from './assets/logo.png';
import { LogoLabel, Body } from './typography';

const StyledLink = styled(HrefLink)`
    text-decoration: none;
`;

const Container = styled.header`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${TRANSPARENT_DARK};

    ${media.desktop`
        padding: 30px 60px;
        display: flex;
        flex-direction: column;
    `}
`;

const Image = styled.img`
    width: 52px;
    height: 42px;
`;

const Logo = styled.div`
    display: flex;
    padding: 10px;

    ${media.desktop`
        padding: 28px 0;
    `}
`;

const Separator = styled.div`
    display: none;
    width: 100%;
    height: 1px;
    background-color: ${WHITE};

    ${media.desktop`
        display: block;
    `}
`;

const Toggler = styled.div`
    display: block;
    position: absolute;
    top: 15px;
    right: 10px;

    ${media.desktop`
        display: none;
    `}
`;

const Nav = styled.nav`
    max-height: 0;
    transition: max-height 0.25s ease-out;
    overflow: hidden;

    &.open {
        max-height: 500px;
        transition: max-height 0.25s ease-out;
    }

    ul {
        list-style: none;
        margin-top: 0;

        li {
            a {
                text-decoration: none;
                color: #F2F2F2;
                font-size: 18px;
                line-height: 40px;
                transition: 0.3s ease-out;

                span:hover {
                    color: ${PINK_TEXT};
                }
            }
        }

        .active {
            color: #5FC0DC;
        }
    }

    ${media.desktop`
        display: inline-block !important; /* prevent the nav to hide when resize */
        position: relative;
        width: auto;
        padding: 5px 0;
        max-height: 200px;
        float: left;

        ul {
            padding-left: 0;
            margin: 0;
            display: flex;

            li {
                display: inline-block;
                padding-right: 20px;
            }
        }
    `}
`;

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeNav = () => {
        setIsOpen(false);
    };

    return <Container id="home">
        <div className="content">
            <StyledLink to="/" onClick={closeNav}>
                <Logo>
                    <Image src={LogoImg} alt="AMIDY" />
                    <LogoLabel>AMIDY</LogoLabel>
                </Logo>
            </StyledLink>
            <Separator />
            <Toggler className="toggler">
                <FontAwesomeIcon size='2x' color={GREY_TRANSPARENT_TEXT} icon={faBars} onClick={() => {
                    setIsOpen(!isOpen);
                }}/>
            </Toggler>
            <Nav className={isOpen ? 'open' : ''}>
                <ul>
                    <li>
                        <Link to="top" onClick={closeNav}><Body>Home</Body></Link>
                    </li>
                    <li>
                        <Link to="about" onClick={closeNav}><Body>About</Body></Link>
                    </li>
                    <li>
                        <Link to="download" onClick={closeNav}><Body>Download</Body></Link>
                    </li>
                    <li>
                        <Link to="highlights" onClick={closeNav}><Body>Key Highlights</Body></Link>
                    </li>
                    <li>
                        <Link to="contacts" onClick={closeNav}><Body>Contacts</Body></Link>
                    </li>
                    <li>
                        <StyledLink to="/support" onClick={closeNav}><Body>Support</Body></StyledLink>
                    </li>
                </ul>
            </Nav>
        </div>
    </Container>;
}

export default Header;