import React from 'react';
import styled from 'styled-components';

import { GREY_BG, TRANSPARENT_DARK } from './colors';
import media from "./media";
import { Body, H2 } from './typography';

import Img1 from './assets/h1.jpg';
import Img2 from './assets/h2.jpg';
import Img3 from './assets/h3.jpg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    width: 100%;
    background-color: ${GREY_BG};
    min-height: 100px;
    overflow-x: hidden;

    ${media.desktop`
        padding: 100px 0 30px 0;
    `}
`;

const Label = styled(Body)`
    margin-top: 20px;
    margin-bottom: 70px;
    max-width: 800px;
    width: 100%;
    overflow: hidden;

    ${media.desktop`
        padding: 50px 60px 40px;
        width: auto;
    `}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${TRANSPARENT_DARK};

    ${media.desktop`
        flex-direction: row;
    `}
`;

const ImageColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 300px;
`;

const ImageTitle = styled(H2)`
    text-align: left;
    margin: 40px 20px 0;
    font-size: 28px;
`;

const TextDiv = styled(Body)`
    text-align: left;
    margin: 20px;
`;

const Image = styled.img`
    width: 300px;

    ${media.desktop`
        width: 100%;
    `}
`;

const HighlightsSection = () => {
    return <Container>
        <H2>Key Highlights</H2>
        <Label>We’re proud to present Amidy, the publication you’ve been waiting for. Take a look at the key highlights below to learn more about its contents and benefits, and download your own copy today!</Label>
        <Content>
            <ImageColumn>
                <Image src={Img1} alt="" />
                <ImageTitle>Online presence</ImageTitle>
                <TextDiv>
                    Meet and chat with people here and now. It allows our users to build a strong connection from first moment.
                </TextDiv>
            </ImageColumn>
            <ImageColumn>
                <Image src={Img2} alt="" />
                <ImageTitle>Not only dating</ImageTitle>
                <TextDiv>
                    Here you can speak with people in common chat, find not only a partner, but also friends or discuss hot topics.
                </TextDiv>
            </ImageColumn>
            <ImageColumn>
                <Image src={Img3} alt="" />
                <ImageTitle>App like going out</ImageTitle>
                <TextDiv>
                    You will have a fun at online locales, which collects presented people. Find your favourite place and see who you can meet there.
                </TextDiv>
            </ImageColumn>
        </Content>
    </Container>;
};

export default HighlightsSection;