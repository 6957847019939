import React from 'react';
import { Router, Route } from 'react-router';
import styled from 'styled-components';

import Header from './Header';
import SliderSection from './SliderSection';
import AboutSection from './AboutSection';
import DownloadSection from './DownloadSection';
import HighlightsSection from './HighlightsSection';
import CompanySection from './CompanySection';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';
import Deletion from './Deletion';
import GotoBar from './GotoBar';
import Support from './Support';

import media from "./media";

import history from './history';

import './App.css';

const HomeDiv = styled.div`
  width: 100%;
  height: 63px;

  ${media.desktop`
        height: 210px;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollView = styled.div`
  overflow-y: auto;
  flex: 1;
`;

function App() {
  return (
    <Container className="App">
      <Router history={history}>
        <Route path="/" exact>
          <Header />
          <HomeDiv id="top" />
          <ScrollView>
            <SliderSection />
            <AboutSection />
            <DownloadSection />
            <HighlightsSection />
            <CompanySection />
            <Footer />
          </ScrollView>
        </Route>
        <Route path="/privacy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/delete" exact>
          <Deletion />
        </Route>
        <Route path="/support" exact>
          <Header />
          <HomeDiv id="top" />
          <Support />
        </Route>
        <Route path="/gotobar/:id/:name" exact>
          <GotoBar />
        </Route>
      </Router>
    </Container>
  );
}

export default App;
