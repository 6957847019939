import React from 'react';
import styled from 'styled-components';
import { TRANSPARENT_DARK, PINK } from './colors';
import media from "./media";
import { Body, H2 } from './typography';

import Frame from './assets/frame.png';

const Container = styled.div`
    background-color: ${PINK};
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;

    ${media.desktop`
        flex-direction: row;
        padding: 60px;
        padding-right: 20px;
    `}
`;

const About = styled.div`
    background-color: ${TRANSPARENT_DARK};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
`;

const Description = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    > * {
        text-align: left;
    }
`;

const Image = styled.img`
    width: 350px;
    margin-top: -4px;
    margin-bottom: -29px;
    align-self: center;

    ${media.desktop`
        width: 400px;
        margin-top: -24px;
        margin-bottom: -49px;
    `}
`;

const Title = styled(H2)`
    margin-top: -30px;
    margin-bottom: 40px;
`;

const BodyTitle = styled(Body)`
    font-weight: 600;
    margin-top: 10px;
`;

const AboutSection = () => {
    return <>
        <Container>
            <About>
                <Title>About</Title>
                <Description>
                    <Body>
                        Brand new dating app. Meet real people here online. Join dating virtual reality.
                    </Body>
                    <Body>
                        Absolutely new dating application, which connecting people in virtual places around the world. You can meet and speak with interesting people in virtual bars, restaurants and etc. Discover your favorite location and make matches in real-time anywhere.
                    </Body>
                    <BodyTitle>
                        PUBLIC AND PRIVATE CONVERSATION
                    </BodyTitle>
                    <Body>
                        Join virtual places and discuss with any people there or find your match and know the person better in private discussion.
                    </Body>
                    <BodyTitle>
                        SEE WHO LIKED YOU
                    </BodyTitle>
                    <Body>
                        Amidy is an open and transparent way to find people. You can set your searching criteria to easily find matching people, but it won't limit you to meet also other interesting people.
                    </Body>
                    <BodyTitle>
                        LOCATIONS ALL OVER THE WORLD
                    </BodyTitle>
                    <Body>
                        We have created a virtual world with places where people can meet online. Bars, restaurants, cafes and many other places which are covering around the world along people joined Amidy.
                    </Body>
                    <BodyTitle>
                        BE INVOLVED AND USE THE MOMENT
                    </BodyTitle>
                    <Body>
                        Don't lose the opportunity to start a conversation with people you liked immediately to keep in contact because here is like in real life.
                    </Body>
                </Description>
                <div id="download" />
            </About>
            <Image src={Frame} />
        </Container>
        
    </>;
};

export default AboutSection;