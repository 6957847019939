import React, { useEffect } from 'react';
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

const GotoBar = ({
    match,
    location,
    history,
}) => {
    const { id, name } = match.params;

    useEffect(() => {
        var now = new Date().valueOf();
        setTimeout(function () {
            if (new Date().valueOf() - now > 100) return;
            window.location = '/#download';
        }, 25);
        window.location = `amidy://location/${id}`;
    }, [id]);

    return <div>
        <Helmet>
            <title>{name} - in Amidy universe</title>
            <meta name="description" content="You're invited to join your friend in Amidy application" />
        </Helmet>
        Loading...
    </div>;
}

export default withRouter(GotoBar);