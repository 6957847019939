import React from 'react';
import styled from 'styled-components';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-scroll';

import { GREY_BG } from './colors';
import media from "./media";
import Button from './Button';

import { H1 } from './typography';

import img1 from './assets/date1.jpg';
import img2 from './assets/date2.jpg';
import img3 from './assets/date3.jpg';
import img4 from './assets/date4.jpg';
import img5 from './assets/date5.jpg';

import Logo from './assets/logoscreen300.png';

const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
];

const Container = styled.div`
    background-color: ${GREY_BG};
    position: relative;

    ${media.desktop`
        padding: 60px;
    `}
`;

const Content =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Darker = styled.div`
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.desktop`
        top: 60px;
        bottom: 60px;
        left: 60px;
        right: 60px;
    `}
`;

const getConfigurableProps = () => ({
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: false,
    autoPlay: true,
    stopOnHover: true,
    swipeable: false,
    dynamicHeight: false,
    emulateTouch: false,
    autoFocus: false,
    interval: 5000,
    transitionTime: 1000,
    swipeScrollTolerance: 5,
});

const Image = styled.img`
    filter: blur(6px);
    -webkit-filter: blur(8px);
`;

const LogoImg = styled.img`
    width: 50px;

    ${media.desktop`
        width: 100px;
    `}
`;

const ButtonContainer = styled.div`
    margin-top: 20px;

    ${media.desktop`
        margin-top: 50px;
    `}
`

const SliderSection = () => {
    return <Container>
        <Carousel
            infiniteLoop
            {...getConfigurableProps()}
        >
            {images.map((imgSource, index) => <Image key={index} src={imgSource} />)}
        </Carousel>
        <Darker>
            <Content>
                <LogoImg src={Logo} width={100} alt="" />
                <H1>AMIDY</H1>
                <ButtonContainer>
                    <Link to="download"><Button title="Download" onClick={() => {}} /></Link>
                </ButtonContainer>
            </Content>
        </Darker>
        <div id="about" />
    </Container>;
};

export default SliderSection;