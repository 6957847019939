import React from 'react';
import styled from 'styled-components';
import { GREY_TRANSPARENT_TEXT, PINK, PINK_TRANSPARENT, WHITE } from './colors';
import { ButtonLabel } from './typography';

const ButtonComponent = styled.button`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    border-radius: 30px;
    border: 0;
    background-color: ${PINK};
    color: ${WHITE};

    &:disabled {
        background-color: ${GREY_TRANSPARENT_TEXT};
    }

    &:hover {
        &:not(:disabled) {
            background-color: ${PINK_TRANSPARENT};
        }
    }
`;

const Button = ({
    title,
    type,
    disabled,
    onClick,
}) => {
    return <ButtonComponent type={type} disabled={disabled} onClick={onClick}>
        <ButtonLabel>{title}</ButtonLabel>
    </ButtonComponent>
}

export default Button;