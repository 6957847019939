export const GREY_BORDER = '#DFDFDF';
export const GREY = '#7D7D7D';
export const GREY_BG = 'rgb(42,42,42)';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const BLACK_BG = 'rgba(17,17,17,0.75)';
export const GREY_TRANSPARENT_BORDER = 'rgba(255, 255, 255, 0.25)';
export const ALMOST_TRANSPARENT = 'rgba(255, 255, 255, 0.1)';
export const GREY_TRANSPARENT_TEXT = 'rgba(255, 255, 255, 0.5)';
export const TRANSPARENT_FOR_ACTIVE_BUTTON = 'rgba(255, 255, 255, 0.3)';
export const PLACEHOLDER_GREY = 'rgba(255, 255, 255, 0.5)';
export const PLACEHOLDER_GREY2 = 'rgba(0, 0, 0, 0.1)';
export const TRANSPARENT = 'rgba(255, 255, 255, 0)';
export const DARK_PINK = 'rgba(74,47,66,1)';
export const SHINE_COLOR = '#9F498C';
export const TRANSPARENT_DARK = 'rgba(0,0,0,0.9)';
export const LIKE_BUTTON_BG = 'rgba(255, 255, 255, 0.03)';
export const BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.5)';
export const BLUE = 'rgb(114,112,204)';
export const SEPARATOR_BG = 'rgba(196,196,196,0.1)';
export const FOCUSED_INPUT_BG = 'rgb(77,77,77)';


export const PHOTO_EDIT_BG = 'rgba(38, 38, 38, 0.8)';
export const PHOTO_EDIT_TOOLS_BG = 'rgba(196, 196, 196, 0.25)';

export const PURPLE = '#68325C';
export const LIGHT_PINK = '#EFD4E9';
export const PINK = '#F29F9D';
export const PINK_TRANSPARENT = '#F29F9D99';
export const PINK_TEXT = '#FBA69F';
export const BORDER_PINK = '#FFAAA0';
export const RED = '#af1f51';

export const BG_BLACK_SHADE = 'rgba(0, 0, 0, 0.7)';

export const GRAY_BUTTON_TITLE = 'rgb(31,31,31)';